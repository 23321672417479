import ButtonInline from '@pretto/bricks/components/buttons/ButtonInline'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column } from '@pretto/bricks/components/layout/Grid'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'
import styled from 'styled-components'

export const Links = styled.div`
  ${column([2, 4])};
  display: grid;
  grid-gap: ${g(7)};
  grid-template: repeat(3, minmax(0, 1fr)) / auto;
  text-align: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
    grid-template: auto / repeat(3, minmax(0, 1fr));
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([3, 10])};
  }
`

export const LinkButton = styled(ButtonInline).attrs({ isSecondary: true })`
  margin-top: ${g(3)};
`

export const LinkTitle = styled(BaseTypo).attrs({ typo: 'heading16' })`
  margin-bottom: ${g(1)};
`

export const LinkPicto = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.neutral4};
  border-radius: 50%;
  display: flex;
  height: ${g(6)};
  justify-content: center;
  margin-bottom: ${g(3)};
  margin-left: auto;
  margin-right: auto;
  width: ${g(6)};
`

export const Picto = styled.svg`
  height: ${g(3)};
  width: ${g(3)};
`

export const Title = styled(BaseTypo).attrs({ typo: 'heading32' })`
  ${column([2, 4])};
  margin-bottom: ${g(5)};
  text-align: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
    flex-direction: row;
    margin-bottom: ${g(8)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([3, 10])};
  }
`
