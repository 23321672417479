import { Grid } from '@pretto/bricks/components/layout/Grid'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const BlocLink = ({ links, title, ...props }) => (
  <Grid {...props}>
    <S.Title>{title}</S.Title>

    <S.Links>
      {links.map(({ button, description, picto, title }, index) => (
        <div key={index}>
          <S.LinkPicto>
            <S.Picto as={picto} />
          </S.LinkPicto>

          <S.LinkTitle>{title}</S.LinkTitle>

          <p>{description}</p>

          {button && <S.LinkButton href={button.url}>{button.label}</S.LinkButton>}
        </div>
      ))}
    </S.Links>
  </Grid>
)

BlocLink.propTypes = {
  /** Links picto, text & CTA */
  links: PropTypes.arrayOf(
    PropTypes.shape({
      button: PropTypes.shape({
        label: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }),
      description: PropTypes.string.isRequired,
      picto: PropTypes.elementType.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
  /** Text of title */
  title: PropTypes.string.isRequired,
}

export default memo(BlocLink)
